import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import pluralize from 'pluralize';

import notify from 'notify';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import { getUsersDetailed } from 'api/users';
import Modal, { ModalProps } from 'components/Modal';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import DocumentsContext from './context';

interface ShareDocumentModalProps extends ModalProps {
  data?: DocumentNode;
}

const ShareDocumentModal: React.FC<ShareDocumentModalProps> = ({
  data,
  open,
  onClose,
  ...props
}) => {
  const { shareDocument } = useContext(DocumentsContext);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    formState
  } = useForm();
  const [teamMembersOptions, setTeamMembersOptions] = useState<DropdownOption[]>();

  const submit = async values => {
    const customEmails = values.customEmails.split(',').filter(Boolean);
    await shareDocument({
      id: data!.id,
      teamMembers: values.teamMembers,
      customEmails
    });
    if (onClose) onClose();
  };

  const fetchTeamMembers = useCallback(async () => {
    try {
      const users = await getUsersDetailed();
      const teamMembersOptions = users
        .filter(item => item.active && item.name)
        .map(item => ({ value: item.id, text: item.name }));
      setTeamMembersOptions(teamMembersOptions);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  }, []);

  useEffect(() => {
    if (open) fetchTeamMembers();
  }, [fetchTeamMembers, open]);

  const getTeamMembersDropdownText = (value: DropdownOption[]) => {
    if (value.length === 0) return;
    if (value.length === teamMembersOptions?.length) return `All users`;
    if (value.length === 1)
      return teamMembersOptions?.find(item => item.value === value[0])?.text as string;
    return `${value.length} ${pluralize('user', value.length)}`;
  };

  return (
    <StyledModal modalTitle="Share" open={open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name="teamMembers"
          rules={{ required: 'Required' }}
          defaultValue={[]}
          render={({ field: { value, ...field } }) => (
            <Dropdown
              value={value}
              text={getTeamMembersDropdownText(value)}
              label="Select Team Members"
              options={teamMembersOptions}
              error={errors.teamMembers?.message}
              multiple
              optionAll
              tags
              {...field}
            />
          )}
        />
        <Input
          {...register('customEmails', {
            pattern: {
              value: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$/,
              message: 'Invalid email list'
            }
          })}
          label="Custom emails"
          error={errors.customEmails?.message}
        />
        <Button type="submit" disabled={formState.isSubmitting}>
          Share
        </Button>
      </form>
    </StyledModal>
  );
};

export default ShareDocumentModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;

    form {
      .input,
      .dropdown,
      .checkbox,
      .button {
        width: 100%;
        margin: 8px 0;
      }

      .button {
        height: 40px;
      }

      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
