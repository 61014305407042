import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import DocumentsPage from 'views/DocumentsPage';
import Workspace from 'workspace';

const Documents = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/documents" component={DocumentsPage} />
      </Router>
    </Workspace>
  );
};

export default Documents;
