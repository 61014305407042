import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { useDebouncedCallback } from 'use-debounce';
import notify from 'notify';
import { downloadFile, tryGetFirstError } from 'utils/requests';
import {
  uploadDocument,
  deleteDocument,
  deleteFolder,
  downloadDocument as downloadDocumentRequest,
  shareDocuments as shareDocumentsRequest
} from 'api/documents';
import { getFoldersWithDocuments } from './api';
import { Doctypes } from 'api/api.d';
import UploadSuccessIcon from './UploadSuccessIcon';

interface DocumentsContextValues {
  tree: AnyNode[];
  path: Path;
  setPath: React.Dispatch<React.SetStateAction<Path>>;
  currentFolderId: string | undefined;
  fetchDocuments: () => Promise<void>;
  deleteNode: (node: AnyNode) => Promise<void>;
  downloadDocument: (id: string) => Promise<void>;
  uploadFile: (file: File) => Promise<void>;
  shareDocument: (value: {
    id: string;
    teamMembers: number[];
    customEmails: string[];
  }) => Promise<void>;
}

const DocumentsContext = React.createContext({} as DocumentsContextValues);

const getNodeChildren = (tree: AnyNode[], path: Path): AnyNode[] => {
  if (path.length === 0) return tree;
  let stack: AnyNode[] = tree;

  path.forEach(item => {
    const branch = stack.find(node => node.id === item.id);
    if (!branch) {
      // eslint-disable-next-line no-console
      console.error(`Node with id "${item.id}" doesn't exist`);
      return stack;
    }
    if (branch.type !== 'folder') {
      // eslint-disable-next-line no-console
      console.error('Tried to open non folder node');
      return stack;
    }
    stack = branch.children;
  });

  return stack;
};

export const DocumentsContextProvider = ({ children }) => {
  const [fullTree, setFullTree] = useState<AnyNode[]>([]);
  const [path, setPath] = useState<Path>([]);
  const currentFolderId = path[path.length - 1]?.id;

  const fetchDocuments = useCallback(async () => {
    try {
      const tree = await getFoldersWithDocuments();
      setFullTree(tree);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  }, []);

  const deleteNode = async (node: AnyNode) => {
    try {
      if (node.type === 'document') await deleteDocument(node.id);
      else await deleteFolder(node.id);
      notify(
        <>
          <UploadSuccessIcon /> {node.name} deleted successfully
        </>
      );
      fetchDocuments();
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const downloadDocument = async (id: string) => {
    try {
      const { url, name } = await downloadDocumentRequest(id);
      // window.open(url);
      // return;
      downloadFile(url, name);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const shareDocument = async ({ id, teamMembers, customEmails }) => {
    try {
      await shareDocumentsRequest({
        ids: [id],
        teamMembers,
        customEmails: customEmails?.length ? customEmails : undefined
      });
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const uploadFile = async ({ file, doctype }: { file: File, doctype: Doctypes; }) => {
    try {
      await uploadDocument({ file, doctype, folder: currentFolderId });
      fetchDocuments();
      notify(
        <>
          <UploadSuccessIcon /> {file.name} uploaded successfully
        </>
      );
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const tree = useMemo(() => getNodeChildren(fullTree, path), [fullTree, path]);

  useEffect(() => {
    if (fullTree.length === 0) fetchDocuments();
  }, [fetchDocuments, fullTree.length]);

  const values: DocumentsContextValues = {
    tree,
    path,
    setPath,
    currentFolderId,
    fetchDocuments,
    deleteNode,
    downloadDocument,
    uploadFile,
    shareDocument
  };

  return <DocumentsContext.Provider value={values}>{children}</DocumentsContext.Provider>;
};

export default DocumentsContext;
