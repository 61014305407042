import React from 'react';
import pluralize from 'pluralize';
import styled from '@emotion/styled';

import Modal, { ModalProps } from 'components/Modal';
import Button from 'components/Button';

interface DeleteFolderWarningModalProps extends ModalProps {
  data?: FolderNode;
  onConfirm: () => void;
}

const DeleteFolderWarningModal: React.FC<DeleteFolderWarningModalProps> = ({
  data,
  onClose,
  onConfirm,
  ...props
}) => {
  if (!data) return null;
  return (
    <StyledDeleteWarningModal modalTitle={`Delete ${data.name}`} onClose={onClose} {...props}>
      <p>
        This folder contains {data.children.length} {pluralize('item', data.children.length)}
      </p>
      <p>Are you sure?</p>
      <Button onClick={onConfirm}>Yes</Button>
      <Button secondary onClick={onClose}>
        No
      </Button>
    </StyledDeleteWarningModal>
  );
};

export default DeleteFolderWarningModal;

const StyledDeleteWarningModal = styled(Modal)`
  .modal-body {
    font-size: 12px;
    line-height: 16px;

    .button {
      width: 100%;

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
`;
