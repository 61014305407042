import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { formatBytes } from 'utils/formatting';
import Button from 'components/Button';
import Icon, { IconName } from 'components/Icon';
import PopMenu from 'components/PopMenu';

interface TreeNodeProps extends Omit<React.HTMLAttributes<HTMLLIElement>, 'onClick'> {
  data: AnyNode;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>, data: AnyNode) => void;
  onDeleteClick?: (data: AnyNode) => void;
  onShareClick?: (data: DocumentNode) => void;
}

const TreeNodeMobile: React.FC<TreeNodeProps> = ({
  data,
  onClick,
  onDeleteClick,
  onShareClick,
  className,
  ...props
}) => {
  const menuButtonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const getIcon = (type: typeof data.type): IconName => {
    switch (type) {
      case 'folder':
        return 'document-folder';
      case 'document':
        return 'document-file';
      default:
        return 'document-file';
    }
  };

  const handleClick = e => {
    if (onClick) onClick(e, data);
  };

  const handleMenuClick = e => {
    e.stopPropagation();
    setMenuOpen(v => !v);
  };

  const handleDeleteClick = e => {
    e.stopPropagation();
    setMenuOpen(false);
    if (onDeleteClick) onDeleteClick(data);
  };

  const handleShareClick = e => {
    e.stopPropagation();
    setMenuOpen(false);
    if (onShareClick) onShareClick(data as DocumentNode);
  };

  return (
    <StyledTreeNodeMobile
      className={clsx('tree-node', data.type, className)}
      onClick={handleClick}
      {...props}>
      <div className="tree-node__top">
        <Icon name={getIcon(data.type)} />
        {data.name}
        <Button ref={menuButtonRef} className="tree-node__menu" onClick={handleMenuClick} simple>
          <Icon name="menu-dots" />
        </Button>
        {createPortal(
          <StyledTreeNodeMenu
            target={menuButtonRef.current}
            open={menuOpen}
            placement="bottom-end"
            onOutsideClick={() => setMenuOpen(false)}
            outsideClickNoTarget>
            {data.type === 'document' && (
              <Button simple onClick={handleShareClick}>
                Share
              </Button>
            )}
            <Button simple onClick={handleDeleteClick}>
              Delete
            </Button>
          </StyledTreeNodeMenu>,
          document.querySelector('#modal-root')!
        )}
      </div>
      <div className="tree-node__bottom">
        <span className="tree-node__date">
          {dayjs(data.createdOn).format('MMM DD, YYYY, HH:mm A')}
        </span>
        <span className="tree-node__type">
          {' · '}
          {data.displayType}
        </span>
        <span className="tree-node__size">
          {' · '}
          {data.type === 'document' && formatBytes(data.size)}
        </span>
      </div>
    </StyledTreeNodeMobile>
  );
};

export default TreeNodeMobile;

const StyledTreeNodeMobile = styled.li`
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 0;

  .tree-node__top {
    display: flex;
    margin-bottom: 8px;
  }

  .tree-node__menu {
    margin-left: auto;
  }

  .tree-node__bottom {
    padding-left: 24px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  .icon {
    vertical-align: middle;
    margin-right: 8px;
  }
  &.folder .icon {
    fill: ${props => props.theme.colors.grayDark};
  }
  .tree-node__name .icon {
    margin: 0 8px 2px 0;
  }
  .tree-node__date,
  .tree-node__type,
  .tree-node__size {
    color: ${props => props.theme.colors.grayDark};
  }

  &:hover {
    background: ${props => props.theme.colors.seashell};
  }
`;

const StyledTreeNodeMenu = styled(PopMenu)`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 8px 12px;
  width: 160px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};

  .button {
    justify-content: left;
    margin: 8px 0;
  }
`;
