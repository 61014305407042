import Icon from 'components/Icon';
import React from 'react';

const UploadSuccessIcon = props => (
  <Icon
    name="checkmark"
    style={{ fill: 'white', width: 22, height: 22, marginRight: 8 }}
    {...props}
  />
);
export default UploadSuccessIcon;
