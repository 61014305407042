import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import notify from 'notify';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import Modal, { ModalProps } from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import { createFolder } from './api';
import DocumentsContext from './context';
import UploadSuccessIcon from './UploadSuccessIcon';

const CreateFolderModal: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const { currentFolderId, fetchDocuments } = useContext(DocumentsContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState
  } = useForm();

  const submit = async values => {
    try {
      await createFolder({ name: values.name, parentId: currentFolderId });
      if (onClose) onClose();
      fetchDocuments();
      notify(
        <>
          <UploadSuccessIcon /> Folder "{values.name}" created successfully
        </>
      );
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  return (
    <StyledModal modalTitle="Create New Folder" onClose={onClose} {...props}>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          {...register('name', { required: 'Required' })}
          label="Name"
          placeholder="Enter name"
          error={errors.name?.message}
        />
        <Button type="submit" disabled={formState.isSubmitting}>
          Create
        </Button>
      </form>
    </StyledModal>
  );
};

export default CreateFolderModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;

    form {
      .input,
      .button {
        width: 100%;
        margin: 8px 0;
      }

      .button {
        height: 40px;
      }

      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
