import React, { useContext, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import clsx from 'clsx';

import notify from 'notify';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import { Button, Input, Icon, ModalConfirm } from 'components';
import MainLayout from 'components/MainLayout';
import PopMenu from 'components/PopMenu';
import DocumentsContext, { DocumentsContextProvider } from './context';
import TreeNode from './TreeNode';
import TreeNodeMobile from './TreeNodeMobile';
import UploadFileModal from './UploadFileModal';
import CreateFolderModal from './CreateFolderModal';
import DeleteFolderWarningModal from './DeleteFolderWarningModal';
import ShareDocumentModal from './ShareDocumentModal';
import DropFiles from './DropFiles';

const DocumentsPage = () => {
  const {
    tree: unfilteredTree,
    deleteNode,
    downloadDocument,
    path,
    setPath,
    uploadFile
  } = useContext(DocumentsContext);
  const layout = useLayout();
  const [search, setSearch] = useState<string>('');
  const [searchDebounced] = useDebounce(search, 300);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [folderDeleteWarningModalData, setFolderDeleteWarningModalData] = useState<FolderNode>();
  const [documentDeleteWarningModalData, setDocumentDeleteWarningModalData] = useState<
    DocumentNode
  >();
  const [shareDocumentModalData, setShareDocumentModalData] = useState<DocumentNode>();
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const searhButtonRef = useRef(null);

  const tree = useMemo(() => {
    return unfilteredTree.filter(item =>
      item.name.toLowerCase().includes(searchDebounced.toLowerCase())
    );
  }, [searchDebounced, unfilteredTree]);

  const handleTreeNodeClick = (_e: any, node: AnyNode) => {
    switch (node.type) {
      case 'folder':
        setSearch('');
        setPath(items => [...items, { id: node.id, breadcrumb: node.name }]);
        break;
      case 'document':
        downloadDocument(node.id);
        break;
      default:
        break;
    }
  };

  const handleBreadcrumbClick = e => {
    const { value } = e.target;
    setSearch('');
    setPath(path => {
      return path.slice(0, path.findIndex(item => item.id === value) + 1);
    });
  };

  const returnToRoot = () => {
    setSearch('');
    setPath([]);
  };

  const returnToPreviousFolder = () => {
    setSearch('');
    setPath(path => path.slice(0, path.length - 1));
  };

  const hanleNodeDeleteClick = (node: AnyNode) => {
    if (node.type === 'folder' && node.children.length !== 0) {
      setFolderDeleteWarningModalData(node);
    } else {
      setDocumentDeleteWarningModalData(node as DocumentNode);
    }
    //  else deleteNode(node);
  };

  const deleteDocument = () => {
    deleteNode(documentDeleteWarningModalData!);
    setDocumentDeleteWarningModalData(undefined);
  };

  const handleFileDrop = (file: File) => {
    notify(`Uploading ${file.name}`);
    uploadFile(file);
  };

  const searchInput = (
    <Input
      className="documents-page__search"
      placeholder="Search"
      icon="search"
      iconPosition="left"
      value={search}
      onChange={e => setSearch(e.target.value)}
    />
  );

  return (
    <MainLayout>
      <StyledDashboardPage className="documents-page">
        <div className="documents-page__header">
          {layout === 'desktop' ? (
            <>
              <h4
                className={clsx('documents-page__title', { breadcrumb: path.length !== 0 })}
                onClick={returnToRoot}
                role="button"
                tabIndex={0}>
                Documents
              </h4>
              <div className="documents-page__breadcrumbs">
                {path.map(item => (
                  <button
                    key={item.id}
                    value={item.id}
                    className="breadcrumb"
                    onClick={handleBreadcrumbClick}>
                    <Icon name="chevron" />
                    {item.breadcrumb}
                  </button>
                ))}
              </div>
            </>
          ) : (
              <>
                {path.length === 0 ? (
                  <h4 className={clsx('documents-page__title', { breadcrumb: path.length !== 0 })}>
                    Documents
                  </h4>
                ) : (
                    <div className="documents-page__mobile-folder">
                      <Button onClick={returnToPreviousFolder}>
                        <Icon name="arrow-simple" />
                      </Button>
                      <h5>{path[path.length - 1].breadcrumb}</h5>
                    </div>
                  )}
              </>
            )}
          <div className="documents-page__controls">
            {layout === 'mobile' ? (
              <>
                <Button
                  ref={searhButtonRef}
                  className="documents-page__mobile-search-btn"
                  secondary
                  onClick={() => setSearchMenuOpen(v => !v)}>
                  <Icon name="search" />
                </Button>
                <PopMenu
                  open={layout === 'mobile' && searchMenuOpen}
                  target={searhButtonRef.current}>
                  {searchInput}
                </PopMenu>
              </>
            ) : (
                searchInput
              )}

            <Button
              className="documents-page__folder_btn"
              secondary
              onClick={() => setCreateFolderModalOpen(true)}>
              <Icon name="folder-plus" />
            </Button>
            <Button className="documents-page__upload_btn" onClick={() => setUploadModalOpen(true)}>
              {layout === 'desktop' ? 'Upload' : <Icon name="plus" />}
            </Button>
          </div>
        </div>
        <DropFiles className="documents-container" onDrop={handleFileDrop}>
          {layout === 'desktop' ? (
            <table className="documents-tree">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Type</th>
                  <th>Size</th>
                  {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {tree.map(node => (
                  <TreeNode
                    key={node.id}
                    data={node}
                    onClick={handleTreeNodeClick}
                    onDeleteClick={hanleNodeDeleteClick}
                    onShareClick={data => setShareDocumentModalData(data)}
                  />
                ))}
              </tbody>
            </table>
          ) : (
              <ul className="documents-tree-mobile">
                {tree.map(node => (
                  <TreeNodeMobile
                    key={node.id}
                    data={node}
                    onClick={handleTreeNodeClick}
                    onDeleteClick={hanleNodeDeleteClick}
                    onShareClick={data => setShareDocumentModalData(data)}
                  />
                ))}
              </ul>
            )}
          {unfilteredTree.length === 0 && (
            <p className="documents-page__empty">No documents yet.</p>
          )}
          {unfilteredTree.length !== 0 && tree.length === 0 && search && (
            <p className="documents-page__empty">No search results</p>
          )}
        </DropFiles>
      </StyledDashboardPage>
      <UploadFileModal open={uploadModalOpen} onClose={() => setUploadModalOpen(false)} />
      <CreateFolderModal
        open={createFolderModalOpen}
        onClose={() => setCreateFolderModalOpen(false)}
      />
      <DeleteFolderWarningModal
        open={Boolean(folderDeleteWarningModalData)}
        data={folderDeleteWarningModalData}
        onConfirm={() => {
          setFolderDeleteWarningModalData(undefined);
          deleteNode(folderDeleteWarningModalData!);
        }}
        onClose={() => setFolderDeleteWarningModalData(undefined)}
      />
      <DeleteFolderWarningModal
        open={Boolean(folderDeleteWarningModalData)}
        data={folderDeleteWarningModalData}
        onConfirm={() => {
          setFolderDeleteWarningModalData(undefined);
          deleteNode(folderDeleteWarningModalData!);
        }}
        onClose={() => setFolderDeleteWarningModalData(undefined)}
      />
      <ShareDocumentModal
        open={Boolean(shareDocumentModalData)}
        data={shareDocumentModalData}
        onClose={() => setShareDocumentModalData(undefined)}
      />
      <ModalConfirm
        modalTitle="Delete document"
        open={Boolean(documentDeleteWarningModalData)}
        onSubmit={deleteDocument}
        onClose={() => setDocumentDeleteWarningModalData(undefined)}>
        Are you sure you wan to delete this document? This action can't be undone
      </ModalConfirm>
    </MainLayout>
  );
};

export default () => (
  <DocumentsContextProvider>
    <DocumentsPage />
  </DocumentsContextProvider>
);

const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .documents-page {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
      outline: none;

      &.breadcrumb {
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    &__breadcrumbs {
      display: flex;
      height: 32px;
      .breadcrumb {
        display: flex;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        white-space: nowrap;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        &:hover,
        &:active {
          opacity: 0.7;
        }
        .icon {
          flex-shrink: 0;
          transform: rotate(270deg);
          width: 24px;
          height: 24px;
        }
      }
    }

    &__mobile-folder {
      display: flex;
      align-items: center;

      h5 {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
      }

      .button {
        background: ${props => props.theme.colors.seashell};
        margin-right: 12px;

        .icon {
          vertical-align: middle;
          fill: black;
        }
      }
    }
    &__controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      & > .button,
      & > .input {
        margin-left: 16px;
        height: 32px;
      }

      .input-component {
        height: 32px !important;
      }

      .button .icon {
        vertical-align: middle;
      }
    }

    &__search {
      width: 100%;
      max-width: 300px;
    }

    &__upload_btn {
      width: 90px;
    }
    &__empty {
      padding: 20px;
      text-align: center;
    }
  }

  .search {
    .input-wrapper {
      .input-component {
        height: 32px;
      }
    }
  }

  .documents-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 32px;
  }

  .documents-tree {
    width: 100%;
    thead th {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: ${props => props.theme.colors.grayDark};
      padding-bottom: 9px;
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }

  .documents-tree-mobile {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .documents-page {
      &__header {
        margin-bottom: 0;
      }

      &__controls {
        .button {
          width: auto;
          margin-left: 8px !important;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .documents-page {
      &__folder_btn {
        margin-left: 8px;
      }
    }
  }
`;
