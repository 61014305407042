import React from 'react';
import clsx from 'clsx';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';

import styled from '@emotion/styled';
import Icon from 'components/Icon';

interface DropFilesProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onDrop'> {
  greedy?: boolean;
  onDrop: (file: File) => void;
}

const DropFiles: React.FC<DropFilesProps> = ({ className, onDrop, children, ...props }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop(item) {
      const file = (item as any).files[0] as File;
      if (file) onDrop(file);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  return (
    <StyledDropZone
      ref={drop}
      className={clsx('dropzone', className, { drag: isActive })}
      {...props}>
      {isActive && (
        <div className="drop-here">
          <div>Drop files here</div>
          <Icon name="cloud-upload" />
        </div>
      )}
      {children}
    </StyledDropZone>
  );
};

export default props => (
  <DndProvider backend={HTML5Backend}>
    <DropFiles {...props} />
  </DndProvider>
);

const StyledDropZone = styled.div`
  &.drag {
    & * {
      pointer-events: none;
    }
  }

  .drop-here {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(241, 241, 241, 0.9);
    border: 1px dashed ${props => props.theme.colors.red};
    position: absolute;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ff7272;

    .icon {
      fill: #ff7272;
      width: 80px;
      height: 83px;
    }
  }
`;
